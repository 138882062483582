@media only screen and (max-width: 1450px) {
    .top-nav .brand {
        padding: 1px 10px 0 10px;
    }
    #queue-column {
        height: calc(100vh - 116px);
    }
    .mat-sidenav .h-100 {
        height: calc(100vh - 60px);
    }
    #main-content {
        height: calc(100vh - 60px);
    }
    .h-100 .brand {
        display: none;
    }
    .expand-nav {
        display: none !important;
    }
}

@media only screen and (max-width: 1200px) {
    .gray-background {
        padding-top: 1.25rem;
        // padding-bottom: 2rem;
    }

    .gray-background .mat-radio-container {
        padding-bottom: 1.5rem;
    }

    .gray-background .mat-radio-button .mat-radio-ripple {
        top: calc(50% - 22px) !important;
    }
}

@media only screen and (max-width: 991px) {
    #claim-files .text-truncate {
        max-width: 22vw;
    }
}

@media only screen and (max-width: 900px) {
    .login-graphic {
      display: none;
    }
    .login-form {
        border-top-left-radius: 1em;
        border-bottom-left-radius: 1em;
    }
    .registration-graphic {
        display: none;
    }
    .registration-form {
        width: 100vw;
    }
}

// @media only screen and (max-width: 1486px) {
//     .login-overlay-text {
//         top: 38vh;
//     }
//     .login-overlay-text::after {
//         font-size: 4.1vw;
//         line-height: 3.6rem;
//     }
//     .login-overlay-subtext::after {
//         font-size: 3vh;
//     }
// }

@media only screen and (max-height: 800px) {
    .container-login100 .login-graphic {
        background-position: 0 0;
    }
}

@media only screen and (max-width: 768px) {
    .fixed-width-modal {
        min-width: auto;
        max-width: auto;
    }
    .new-claim-modal {
        min-width: auto;
        max-width: auto;
    }
}

// Small Device Media Queries

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .container {
        max-width: none !important;
    }
    .login-container, .registration-container {
        background-color: #fff;
    }
    .container-login100 .login-form {
        width: 100vw;
        height: 90vh;
        border-radius: 0;
        padding: 3em 2em 0 2em;
    }
    .container-login100, .container-thankyou100 {
        padding: 0px;
    }
    // .container-register100 {
    //     padding: 20px 15px;
    // }
    .access-wrapper {
        box-shadow: none;
    }
    .registration-form {
        overflow-y: scroll;
        padding-top: 3vh;
    }
    .container-login100 {
        padding-top: 10vh;
    }
    .brand-register {
        width: 80vw;
    }
    #search {
        width: 100%;
        // margin-top: 74px;
    }
    #search .mat-form-field-wrapper {
        width: 100vw;
    }
    #filter {
        width: 100%;
        // margin-top: 74px;
    }
    #filter .mat-form-field-wrapper {
        width: 100vw;
    }
    .mat-sidenav .h-100 {
        height: calc(100vh - 60px) !important;
    }
    .truncated-text {
        max-width: 100%;
    }
    #main-content {
        width: 100%;
        flex: none;
        height: calc(100vh - 60px);
        // padding-top: 30px;
    }
    .lg-edit-btn {
        display: none !important;
    }
    .btn-container {
        bottom: 20px;
        right: 20px;
    }
    .quote-dialog {
        max-width: 90vw !important;
    }
    .mat-dialog-container {
        max-height: 80vh !important;
        width: 100vw !important;
        border-radius: 4px !important;
        padding: 1.5em 1em 2em !important;
        box-shadow: none !important;
        overflow-x: hidden !important;
    }
    .brand-modal {
        height: 70px;
        width: 70px;
        margin-bottom: -16px;
    }
    .modal-h1 {
        font-size: 22px !important;
        margin-left: -8px !important;
    }
    #details-container.p-5 {
        padding: 0 !important;
    }
    .brand-background {
        background-size: 70vw !important;
    }
    #details-button-row {
        display: none !important;
    }
    #details-button-row-mobile {
        display: block !important;
    }
    #details-button-row-mobile .mat-icon-button {
        background-color: #f0f1f4;
    }
    #details-button-row-mobile .mobile-back-button {
        background-color: transparent;
    }
    #details-button-row-mobile .mobile-back-button {
        margin-left: -1rem;
    }
    #details-button-row-mobile .material-icons {
        margin-top: -1px;
    }
    #ttm-box .gray-background {
        padding: 1.3em 1em;
    }
    #claim-files .text-truncate {
        max-width: 60vw;
    }
    .application-overflow {
        overflow: hidden;
    }
    .confirm__checkmark {
        width: 120px;
        height: 120px;
    }
    // .no-sliding {
    //     margin-left: 0;
    //     transition: margin 700ms;
    // }
    #queue-column {
        width: 100% !important;
        transition: margin 700ms;
        // margin-top: 74px;
    }
    .sliding {
        margin-left: -100%;
        transition: margin 700ms;
    }
    #mobile-update-message {
        display: block;
    }
}