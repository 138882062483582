$container-error: #F44336;
$container-info: #0066CC;
$container-warn: #FF9900;
$container-success: #00CC33;

body, html {
    height: 100%;
    overflow-x: hidden;
}
/*
@class cc
Common CSS classes used for cookie consent prompt
*/
.cc-window {
    color: #000000;
    background-color: #ffffff;
}

.cc-message {
    color: #000000;
}

.cc-compliance {
    display: flex;
    align-items: center;
    padding-top: 0px;
}

.cc-divider {
    display: none;
}

.cc-link {
    display: none;
}

.cc-window.cc-banner {
    padding: 2.5em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cc-btn {
    text-decoration: none;
}

.container {
    width: 95%;
    max-width: 95% !important;
}

a:hover {
    cursor: pointer;
}

#details-container p, #documents-container p {
    font-size: 13px;
}

#training-container p, #details-container p {
    font-size: 13px;
}

#details-container span.mat-slide-toggle-content, #details-container span.mat-body,
#documents-container span.mat-slide-toggle-content, #documents-container span.mat-body {
    font-size: 13px;
}

#details-container span.mat-slide-toggle-content, #details-container span.mat-body,
#training-container span.mat-slide-toggle-content, #training-container span.mat-body {
    font-size: 13px;
}

#documents-container .mat-card-header-text {
    margin: 0;
}

#training-container .mat-card-header-text {
    margin: 0;
}

.mat-flat-button.mat-primary:active {
    transform: translateY(2px);
    transition: 0.3s;
}

.mat-flat-button.mat-primary.mat-button-disabled:active, .mat-flat-button.mat-accent.mat-button-disabled:active, .mat-flat-button.mat-warn.mat-button-disabled:active, .mat-flat-button.mat-button-disabled.mat-button-disabled:active, .mat-raised-button.mat-primary.mat-button-disabled:active, .mat-raised-button.mat-accent.mat-button-disabled:active, .mat-raised-button.mat-warn.mat-button-disabled:active, .mat-raised-button.mat-button-disabled.mat-button-disabled:active, .mat-fab.mat-primary.mat-button-disabled:active, .mat-fab.mat-accent.mat-button-disabled:active, .mat-fab.mat-warn.mat-button-disabled:active, .mat-fab.mat-button-disabled.mat-button-disabled:active, .mat-mini-fab.mat-primary.mat-button-disabled:active, .mat-mini-fab.mat-accent.mat-button-disabled:active, .mat-mini-fab.mat-warn.mat-button-disabled:active, .mat-mini-fab.mat-button-disabled.mat-button-disabled:active {
    transform: none;
}

.lg-edit-btn {
    display: block;
}

.mat-vertical-content {
    padding: 0 0 24px 0 !important;
}

.mat-vertical-content-container {
    margin-left: 0 !important;
}

.mat-stepper-vertical-line::before {
    border-left-width: 0 !important;
    border-left-style: none !important;
}

.queue-item-header {
    color: inherit;
}

.mat-nav-list {
    padding-top: 0 !important;
}

#claim-files a {
    text-decoration: none;
}

#claim-files .mat-icon-button {
    width: 30px;
    height: 30px;
    line-height: 20px;
}

// #claim-files .mat-mini-fab {
//     transition: all 0.2s;
// }

// #claim-files .mat-mini-fab:active {
//     transform: translate(1px, 2px);
//     transition: all 0.2s;
// }


#claim-files .mat-mini-fab:focus {
    box-shadow: none;
}


// #claim-files .mat-mini-fab:focus .mat-ripple { 
//     display: none; 
// }

// #claim-files button.mat-mini-fab .mat-icon {
//     margin-top: -0.23rem;
// }

// #claim-files .mat-card button.delete {
//     margin-left: auto;
// }

#search {
    margin-bottom: -1px;
    z-index: 3;
    position: fixed;
    width: 25vw;
    margin-top: -56px;
}

#search .mat-form-field-wrapper {
    width: 25vw;
    height: 56px;
}

#search .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: transparent;
}

#search .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform:
    translateY(-0.5em)
    scale(0.66);
}

#search input.mat-input-element {
    margin-top: 0.25em;
}

#search .mat-form-field-appearance-fill .mat-form-field-ripple {
    bottom: 0;
    height: 1px;
}

#search .mat-form-field-underline {
    bottom: -1px;
}

#search .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0 0 0 0;
}

#search .mat-form-field-infix {
    border-top: none;
}
#search {
    margin-bottom: -1px;
    z-index: 3;
    position: fixed;
    width: 25vw;
    margin-top: -56px;
    border-bottom: solid 1px #777;
}

div.mat-form-field-wrapper {
    // padding-bottom: 0;
}

#mark-up-select {
    width: 100px;
}

#mark-up-select .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0;
    border-right-style: none;
}

#mark-up-select .mat-form-field-flex {
    width: 50%;
}

#mark-up-input {
    width: 160px;
}

#mark-up-input .mat-form-field-appearance-outline .mat-form-field-outline {
    width: 160px;
}

#mark-up-input .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 0;
}

#filter {
    height: 57px;
    min-height: 57px;
    border-bottom: solid 1px #777;
}

#filter .mat-select {
    left: 30px;
    width: 97%;
}

#filter .mat-form-field {
    height: 56px;
}

#filter .mat-form-field-wrapper {
    width: 25vw;
    height: 50px;
}
#filter .material-icons-round {
    top: -2px;
    position: relative;
}

#filter .mat-form-field-underline {
    bottom: 0.34375em;
}

#filter .mat-form-field-appearance-fill .mat-form-field-ripple {
    display: none;
    height: 0;
}

#filter .mat-form-field-appearance-fill .mat-form-field-underline::before {
    display: none;
}

#filter .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 1em 0.75em 0px 0.75em !important
}


#parts-delete-button button.mat-stroked-button {
    padding: 0 !important;
    min-width: 100%;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

.container-thankyou100 {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.container-register100 {
    width: 100%;
    min-height: 100vh;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    align-items: center;
}

.container-register100 {
    padding: 0;
}

#reg-country-selection {
    font-family: 'proxima-nova', sans-serif;
    font-size: 13px;
    font-weight: 400;
    padding: 0px 0 0 10px;
    font-style: normal;
    color: #777;
}

#reg-country-selection img {
    scale: 1;
    transition: 0.2s;
}

#reg-country-selection img:hover {
    cursor: pointer;
    scale: 1.5;
    transition: 0.4s;
}

.registration-form {
    width: 70vw;
    height: 100%;
    overflow-y: scroll;
    // padding-top: 10vh;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
    opacity: 0.5;
}

h5#login-header {
    padding-top: 1.7em;
    padding-bottom: 0.4em;
    font-size: 15px;
}

#espp-login .mat-spinner, .mat-spinner svg {
    height: 40px !important;
    width: 40px !important;
}

.session-modal-header .mat-button, .mat-stroked-button, .mat-flat-button {
    padding: 0px 0px !important;
    min-width: none !important;
}

.login-form .mat-form-field-wrapper {
    padding-bottom: 1em;
}

.login-form .mat-flat-button {
    padding: 0.4em !important;
}

.chevron-button {
    transition: 300ms ease-in-out;
    transform: rotate(0deg);
}

.chevron-button.rotate {
    transform: rotate(180deg);
}

.volvo-brand img {
    width: 32px;
    height: 32px;
}

hr {
    opacity: 0.15 !important;
}

.fixed-navbar-padding {
    padding-top: 100px;
}

.fixed-top {
    z-index: 1000 !important;
}

.mat-snack-bar-container.snack-info {
    background: $container-info !important;
}

.mat-snack-bar-container.snack-error {
    background: $container-error !important;
}

.mat-snack-bar-container.snack-warning {
    background: $container-warn !important;
}

.mat-snack-bar-container.snack-success {
    background: $container-success !important;
}

.custom-snackbar {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    color: white;
    gap: 10px;
}
.mat-snack-bar-container {
    background: white !important;
    color: black !important;
}

.toast-notification .mat-icon {
    color: #F44336;
}

.toast-notification .material-icons {
    font-size: 28px;
}

.mat-typography h4.secondrow-nav {
    margin-bottom: -3px !important;
}

.mat-menu-panel {
    border-radius: 8px !important;
}

.mat-menu-item {
    font-size: 12px;
}

.mat-menu-item .mat-icon {
    height: 17px;
    width: 17px;
}

.mat-menu-item .material-icons-round {
    font-size: 16px;
}

.navbar {
    height: 100px;
}

.navbar a, .navbar p {
	font-size: 14px;
	font-weight: 600;
	text-decoration: none;
}

.truncated-text {
    max-width: 19vw;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncated-uploads-text {
    max-width: 100vw;
    white-space: nowrap !important;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}

#claim-files .text-truncate {
    vertical-align: middle;
    max-width: 17vw;
}

.claim-dialog {
    width: 50vw;

    .new-claim-modal {
        max-width: 47vw;
    }
}

.list-group-item .badge {
    text-transform: uppercase;
}

main {
    display: flex;
    overflow-x: hidden;
    flex-wrap: nowrap;
}

#main-content {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.list-item .mat-icon-button {
    line-height: inherit;
}

#details-button-row .material-icons {
    font-size: 21px;
    margin-top: -1px;
}

.claim-parts table {
    width: 100%;
}

.claim-parts tr.mat-footer-row {
    font-weight: bold;
}

.align-items-center .mat-form-field-wrapper {
    padding: 0;
}

.example-full-width {
    width: 100%;
}

.mat-checkbox-layout {
    white-space: normal !important;
}

.form-check label {
	font-size: 0.8em;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.825rem;
}

.form-control:focus, .form-select:focus {
	box-shadow: none !important;
}

.btn {
	transition: 0.4s;
}

.btn:hover {
	transition: 0.4s;
}

.btn:focus,.btn:active {
	box-shadow: none !important;
}

.lg-btn {
    border-radius: 4px !important;
	padding: 2em 2.7em !important;
}

.sm-btn {
    border-radius: 4px !important;
	padding: 0.5em 1.8em !important;
	font-size: 13px !important;
	font-weight: 600 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
}

#search .mat-form-field.mat-form-field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
    background-color: transparent;
}

.btn-circle {
	display: block;
	position: fixed;
	height: 3em;
	width: 3em;
	border-radius: 50% !important;
	bottom: 2em;
	right: 2em;
    z-index: 1;
}

.mat-mini-fab:not([class*=mat-elevation-z]) {
    box-shadow: none;
}

.btn-message svg {
	margin-bottom: -0.5em;
	margin-left: -0.1em;
}

.animate-opacity { 
	animation:opac 0.5s;
}
@keyframes opac {
	from{opacity:0} to{opacity:1}
}

.dropdown-menu[data-bs-popper] {
    margin-top: 0.6rem !important;
}

.btn-opacity {
	background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: 30rem !important;
}

.btn-opacity:hover {
	background-color: rgba(255, 255, 255, 0.4) !important;
}
  
#btn-opacity-text {
	color: rgba(255,255,255,1) !important;
}

.action-btn svg {
    margin-top: -0.25em;
}

.action-dropdown .dropdown-item svg {
    margin-top: -0.15em;
    margin-right: 0.5em;
}

.mat-mini-fab:not([class*=mat-elevation-z]) {
    box-shadow: none !important;
}

.xl-button {
    border-radius: 14px !important;
    font-size: 15px;
    padding: 10px 50px !important;
}

.rise-animation {
    transition: all 0.3s;
}

.rise-animation:hover {
    transform: translateY(-3px);
	transition: all 0.3s;
}

.mat-dialog-container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 94vh !important;
    border-radius: 0px !important;
    box-shadow: none;
}

.cdk-overlay-pane {
    height: auto !important;
}

.fixed-width-modal {
    min-width: 550px;
    max-width: 550px;
}

.new-claim-modal, .claim-file-modal {
    min-width: 400px;
    max-width: 400px;
}
  
.btn-container {
    position: fixed;
    bottom: 50px;
    right: 50px;
    display: block;
    z-index: 1000;
    border-radius: 50px;  
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition-duration: 0.2s;
}

.btn-container:active {
    transform: translate(1px, 2px);
    transition-duration: 0.1s;
}

.quote-dialog {
    height: 600px;
    width: 1200px;
    overflow: hidden;
}

span.mat-form-field-required-marker {
    display: none;
}

#details-button-row-mobile {
    display: none !important;
}

#ttm-modal .dismiss-button {
    margin-right: -20px;
    margin-top: -3px;
}

.mat-button-focus-overlay {
    background-color: transparent !important;
}

#mobile-update-message {
    display: none;
}



@keyframes spinner {
    to {transform: rotate(360deg);}
}
   
.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid white;
    border-top-color: black;
    animation: spinner .8s linear infinite;
}